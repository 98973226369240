export const menuItems = [
  {
    route: '/',
    text: 'Главная',
    name: 'Main',
    role: 'independent',
    parentGroup: null,
    includeMobile: true,
    includeDesktop: true
  },
  {
    route: '/reservation',
    text: 'Аренда',
    name: 'Reservation',
    role: 'parent',
    parentGroup: 'reservation',
    includeMobile: true,
    includeDesktop: true
  },
  {
    route: '/services',
    text: 'Услуги',
    role: 'parent',
    name: 'Services',
    parentGroup: 'services',
    includeMobile: true,
    includeDesktop: true
  },
  {
    route: '/payments',
    text: 'Оплата',
    role: 'independent',
    name: 'Payments',
    parentGroup: null,
    includeMobile: true,
    includeDesktop: true
  },
  {
    route: '/profile',
    text: 'Профиль',
    role: 'independent',
    name: 'Profile',
    parentGroup: null,
    includeMobile: true,
    includeDesktop: false
  },
  {
    route: '/room-reservation',
    text: 'Переговорные',
    role: 'child',
    name: 'RoomReservation',
    parentGroup: 'reservation',
    includeMobile: false,
    includeDesktop: false
  },
  {
    route: '/workplaces',
    text: 'Рабочие места и кабинеты',
    role: 'child',
    name: 'Workplaces',
    parentGroup: 'reservation',
    includeMobile: false,
    includeDesktop: false
  },
  // {
  //   route: '/event',
  //   text: 'Ивент-пространства и конференц-залы',
  //   role: 'child',
  //   name: 'Event',
  //   parentGroup: 'reservation',
  //   includeMobile: false,
  //   includeDesktop: false
  // },
  {
    route: '/print',
    text: 'Печать',
    role: 'child',
    name: 'Print',
    parentGroup: 'services',
    includeMobile: false,
    includeDesktop: false
  },
  {
    route: '/passes',
    text: 'Заявки на пропуск',
    role: 'child',
    name: 'Passes',
    parentGroup: 'services',
    includeMobile: false,
    includeDesktop: false
  },
  // {
  //   route: '/it',
  //   text: 'ИТ-услуги',
  //   role: 'child',
  //   name: 'It',
  //   parentGroup: 'services',
  //   includeMobile: false,
  //   includeDesktop: false
  // },
  {
    route: '/tickets',
    text: 'Обращения',
    role: 'child',
    name: 'Tickets',
    parentGroup: 'services',
    includeMobile: false,
    includeDesktop: false
  },
  {
    route: '/payments',
    text: 'Оплата',
    role: 'independent',
    name: 'Payments',
    parentGroup: null,
    includeMobile: false
  },
  // {
  //   route: '/community',
  //   text: 'Резиденты',
  //   name: 'Community',
  //   role: 'independent',
  //   parentGroup: null,
  //   includeMobile: false,
  //   includeDesktop: true
  // },
  {
    route: '/support',
    text: 'Поддержка',
    name: 'Support',
    role: 'independent',
    parentGroup: null,
    includeMobile: false,
    includeDesktop: true
  }
];
