import React, { useEffect, useState, useRef } from 'react';
import Portal, { createContainer } from '../../../../hocs/Portal';
import heic2any from 'heic2any';
import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import { Loader } from '../Loader';
import { AddFile } from '../../icons/core/AddFile';
import Cropper from 'react-cropper';
import { colors } from '../../constants/styleConstants';
import 'cropperjs/dist/cropper.css';
import { FileActionIcon } from '../../icons/core/FileAction';
const FilePreviewContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '8px',
  marginBottom: '10px',
  width: 'fit-content',
  textAlign: 'center'
});
const PreviewImageWrapper = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'alignItems': 'center',
  '& > img': {
    width: '200px',
    height: '200px'
  }
});
const PreviewActionsBlock = styled('div')({
  'display': 'flex',
  'flexDirection': 'row',
  'justifyContent': 'center',
  'alignItems': 'center',
  '& > div': {
    marginRight: '8px'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  'marginTop': '16px',
  'marginBottom': '16px'
});

const PreviewIconBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});
const PreviewIconCaption = styled('p')({
  fontSize: '12px',
  color: `${colors.workkiDarkMain}`,
  fontWeight: '300',
  margin: '0',
  textAlign: 'center'
});

const StatusOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
`;

const StatusContainer = styled.div`
  margin: 0 40px;
`;

const FilePreviewIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5px'
});

const FileInput = styled('input')({
  display: 'none'
});

const FileInputCaption = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'alignItems': 'center',
  'fontSize': '12px',
  'fontWeight': '300',
  '& > p': {
    'margin': 0,
    'fontSize': '12px',
    'fontWeight': '300',
    '&:first-child': {
      color: '#24272A'
    },
    '&:nth-child(2)': {
      color: '#8F9295'
    }
  }
});

const FilePreviewList = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'center',
  marginTop: '10px'
});

const DropZone = styled(Box)(({ isDragActive }) => ({
  'border': `2px dashed ${colors.workkiLightLine}`,
  '&:hover': {
    border: `2px dashed ${colors.workkiRedLine}`
  },
  'borderRadius': '8px',
  'padding': '20px',
  'textAlign': 'center',
  'backgroundColor': isDragActive ? '#f0f0f0' : '#fff',
  'cursor': 'pointer',
  'transition': 'background-color 0.3s ease',
  'margin': '16px 0'
}));

const ModalContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const FileUploadPreview = ({ setFiles, files, fieldType }) => {
  const [isMounted, setMounted] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('unset');
  const [statusShown, setStatusShown] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const cropperRef = useRef(null);

  const MODAL_CONTAINER_ID = 'dropzone-overlay';

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID });
    setMounted(true);
  }, []);

  const checkHEICSupport = file => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = URL.createObjectURL(file);
    });
  };

  const convertHEICToJPEG = async file => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg'
      });
      return new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });
    } catch (error) {
      console.error('Ошибка при конвертации HEIC в JPEG:', error);
      return null;
    }
  };

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);
    const processedFiles = [];

    for (const file of selectedFiles) {
      const isSupported = await checkHEICSupport(file);

      if (!isSupported && file.type === 'image/heic') {
        const convertedFile = await convertHEICToJPEG(file);
        if (convertedFile) {
          processedFiles.push(convertedFile);
        }
      } else {
        processedFiles.push(file);
      }
    }

    setFiles(prevFiles => [...prevFiles, ...processedFiles]);
  };

  const handleFileRemove = index => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleDragOver = event => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragActive(false);
    const selectedFiles = Array.from(event.dataTransfer.files);
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const renderStatusContent = () => {
    const content = {
      unset: () => {
        return;
      },
      loading: () => {
        return <Loader />;
      },
      success: () => {
        return <div></div>;
      },
      error: () => {
        return;
      }
    };

    return content[loadingStatus]();
  };

  const renderFileContent = type => {
    const content = {
      employee: () => {
        return (
          <>
            <p>Загрузить фото на пропуск</p>
            <p>JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      },
      default: () => {
        return (
          <>
            <p>Загрузить фото/скан паспорта</p>
            <p>JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      },
      print: () => {
        return (
          <>
            <p>Прикрепить файл</p>
            <p>PDF, WORD, EXCEL, TEXT, JPG до 15 Мбайт</p>
          </>
        );
      },
      changeCredentials: () => {
        return (
          <>
            <p>Прикрепить файлы</p>
            <p>PDF, DOC, JPEG, HEIC до 20 Мбайт</p>
          </>
        );
      }
    };

    return type ? content[type]() : content.default;
  };

  const handleRotateLeft = index => {
    const file = files[index];
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.height;
      canvas.height = img.width;
      ctx.rotate((-90 * Math.PI) / 180);
      ctx.drawImage(img, -img.width, 0);
      canvas.toBlob(blob => {
        const newFile = new File([blob], file.name, { type: file.type });
        setFiles(prevFiles => prevFiles.map((f, i) => (i === index ? newFile : f)));
      }, file.type);
    };
  };

  const handleCrop = index => {
    const file = files[index];
    setSelectedFile(file);
    setModalOpen(true);
  };

  const handleCropSave = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(blob => {
        const newFile = new File([blob], selectedFile.name, { type: selectedFile.type });
        setFiles(prevFiles => prevFiles.map(file => (file === selectedFile ? newFile : file)));
        setSelectedFile(null);
        setModalOpen(false);
      });
    }
  };

  return (
    <>
      <DropZone
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        isDragActive={isDragActive}
      >
        {(files.length === 0 || fieldType === 'changeCredentials') && (
          <label htmlFor='file-upload'>
            <FileInput id='file-upload' type='file' multiple onChange={handleFileChange} />
            <AddFile defaultColor={'#C8C9CA'} hoverColor={'#DB4344'} spacing={'0'} />
            <FileInputCaption>{renderFileContent(fieldType)}</FileInputCaption>
          </label>
        )}
        {fieldType !== 'changeCredentials' && (
          <FilePreviewList>
            {files.map((file, index) => (
              <FilePreviewContainer key={index}>
                <PreviewImageWrapper>
                  <img src={URL.createObjectURL(file)} alt={file.name} width='100' />
                </PreviewImageWrapper>
                <PreviewActionsBlock>
                  <PreviewIconBlock>
                    <FileActionIcon
                      defaultColor={'#24272A'}
                      type={'rotate'}
                      handleAction={() => handleRotateLeft(index)}
                    />
                    <PreviewIconCaption>Повернуть</PreviewIconCaption>
                  </PreviewIconBlock>
                  <PreviewIconBlock>
                    <FileActionIcon defaultColor={'#24272A'} type={'crop'} handleAction={() => handleCrop(index)} />
                    <PreviewIconCaption>Обрезать</PreviewIconCaption>
                  </PreviewIconBlock>
                  <PreviewIconBlock>
                    <FileActionIcon
                      defaultColor={'#24272A'}
                      type={'delete'}
                      handleAction={() => handleFileRemove(index)}
                    />
                    <PreviewIconCaption>Удалить</PreviewIconCaption>
                  </PreviewIconBlock>
                </PreviewActionsBlock>
              </FilePreviewContainer>
            ))}
          </FilePreviewList>
        )}
      </DropZone>
      {isMounted && (
        <Portal id={MODAL_CONTAINER_ID}>
          {statusShown && (
            <StatusOverlay>
              <StatusContainer style={{ display: 'flex' }}>{renderStatusContent()}</StatusContainer>
            </StatusOverlay>
          )}
        </Portal>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalContainer>
          {selectedFile && (
            <Cropper
              src={URL.createObjectURL(selectedFile)}
              style={{ height: 400, width: '100%' }}
              initialAspectRatio={1}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
            />
          )}
          <Button onClick={handleCropSave}>Сохранить</Button>
        </ModalContainer>
      </Modal>
    </>
  );
};
