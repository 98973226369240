import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { TabsComponent } from '../../../shared/ui/components/Tabs/Tabs';
import { CustomDropdown } from '../../../shared/ui/components/Dropdown';
import { BalanceCard } from '../../../shared/ui/components/Payments/BalanceCard';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { connect, useDispatch, useSelector } from 'react-redux';
import { transactionsSelector } from '../../../store/selectors/TransactionsSelector';
import { resetDeposits, resetTransactions } from '../../../store/actions';
import { getTransactions } from '../../../services/workki/TransactionsActions';
import { SERVICE_TYPE } from '../../../constants';
import { LinkArrow } from '../../../shared/ui/icons/core/LinkArrow';
import { depositsSelector } from '../../../store/selectors/DepositsSelector';
import saveAs from 'file-saver';
import { usePaymentController } from '../model/PaymentController';
import {
  getBinaryFileDeposit,
  getBinaryFileDepositReceipt,
  getDepositBanks,
  getDeposits
} from '../api/DepositsActions';
import { OperationInfoDialog } from '../../../widgets/Payment/OperationInfoDialog';
import dayjs from 'dayjs';
import { useResize } from '../../../shared/utils/hooks/useResize';
import { removeZeros } from '../../../shared/utils/serviceUtils/validationUtils';
import { BalanceDialog } from '../../../widgets/Payment/BalanceDialog';
import { accountSelector, customerSelector } from '../../../store/selectors/DefaultSelectors';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';
import { GeneralInfoDialog } from '../../../widgets/GeneralInfoDialog';
import { PrefillForm, TabsWrapper } from '../../Profile/PrefillProfile/model/PrefillProfileComponent';
import { colors } from '../../../shared/ui/constants/styleConstants';

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  flex-direction: column-reverse;
`;
export const OperationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  justify-content: flex-start;
  overflow-y: auto;
`;
export const OperationContainer = styled.div`
  color: #24272a;
  background-color: white;
  font-family: Commissioner, sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
`;
export const EmptyOperation = styled.p`
  color: ${colors.workkiDarkMiddle};
  margin: 0;
  font-weight: 400;
  text-align: center;
`;

export const Transaction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => (props.direction ? 'end' : 'start')};
  :hover {
    cursor: pointer;
  }
`;

export const Type = styled.div`
  font-size: 16px;
`;

export const Description = styled.div`
  font-size: 14px;
  color: #999;
  flex-wrap: wrap;
  font-weight: 400;
`;

export const Status = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
export const StatusBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > svg {
    margin-right: 4px;
  }
  & > p {
    color: ${props =>
      props.type === 'pending'
        ? '#8F9295'
        : props.type === 'cancelled'
        ? '#BA393A'
        : props.type === 'accepted'
        ? '#008d64'
        : '#24272A'};
    margin: 0;
  }
`;
export const StatusParkingBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > svg {
    margin-right: 4px;
  }
  & > p {
    color: ${props => (props.type === 'pending' ? '#8F9295' : '#BA393A')};
    margin: 0;
  }
`;
export const Amount = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => (props.positive ? '#007D2B' : '#24272A')};
`;

export const Date = styled.div`
  font-size: 14px;
  color: #999;
`;

export const HistoryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  overflow-y: auto;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 8px;
  :first-child {
    margin-right: 8px;
  }
`;

export const GroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  justify-self: flex-start;
  align-items: center;
  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
  }
  & > span {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
  }
  margin: 0;
`;
export const ButtonWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  position: fixed;
  border: 0px white solid;
  background-color: white;
  @media (min-width: 320px) {
    width: 100%;
    margin-top: 16px;
    padding: 16px 16px 60px 16px;
  }
  @media (min-width: 768px) {
    padding: 8px 16px;
    min-width: 340px;
    max-width: 638px;
  }
  @media (min-width: 1366px) {
    min-width: 416px;
    max-width: 599px;
    margin-bottom: 0;
  }
  @media (min-width: 1920px) {
    width: 600px;
  }
`;

const ShowMoreButton = styled.button`
  display: flex;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  font-weight: 400;
  width: 100%;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  color: #575b60;
  background-color: white;
  @media (min-width: 320px) {
    display: none;
  }
  @media (min-width: 1366px) {
    display: flex;
  }
`;
export const Check = styled.p`
  font-weight: 500;
  color: #ba393a;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
`;

const Payments = props => {
  const inputRef = useRef(null);
  const numberRegex = /^([1-9][0-9]*)(\.[0-9]*)?₽?$/;
  const customer = useSelector(customerSelector);
  const account = useSelector(accountSelector);
  const destinationOptions = [
    { value: 'mainAccount', label: 'Общий баланс' },
    { value: 'securityAccount', label: 'Обеспечительный баланс' }
  ];
  const [formData, setFormData] = useState({
    amount: ' ₽',
    destination: destinationOptions[0].value
  });
  const [updateCursor, setUpdateCursor] = useState(false);
  const errors = { amount: '', destination: '' };

  const handleSelectChange = event => {
    setFormData(prevState => ({ ...prevState, destination: event.target.value }));
  };
  const handleApplyMonthFilters = event => {
    setSelectedMonth(event.target.value);
  };
  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
    if (fieldName === 'amount') {
      setFormData(prevState => ({
        ...prevState,
        amount: formData.amount.trim() === '₽' ? formData.amount.replace(/₽/g, '') : formData.amount
      }));
    }
  };

  const [filled, setFilled] = useState({
    amount: false,
    matchPassword: false
  });
  const [isFocused, setIsFocused] = useState({
    amount: false,
    destination: false
  });
  const [disableButton, setDisableButton] = useState(false);
  const handleInputChange = evt => {
    const { name, value } = evt.target;
    const inputValue = value.replace(/\s+/g, '').replace(/₽/g, '');

    updateFilledState(name, inputValue);
    // handleValidation(name, inputValue);
    updateFormData(name, inputValue);
  };
  const updateFilledState = (name, inputValue) => {
    const type = 'legalEntity';
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]:
        name === 'email'
          ? {
              ...prevFilled[name],
              [type]: true
            }
          : inputValue !== ''
    }));
  };

  const updateFormData = (name, inputValue) => {
    const formattedValue = numberRegex.test(inputValue) ? inputValue : '';
    const cleanValue = formattedValue.replace(' ₽', '');
    setDisableButton(cleanValue[cleanValue.length - 1] === '.' || +cleanValue < 1);
    setFormData(prevState => ({
      ...prevState,
      [name]: formattedValue + ' ₽'
    }));
    setUpdateCursor(true);
  };
  const handleEraseValue = (fieldName, type) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: '' + ' ₽'
    }));
  };

  const { dispatchGetBinaryFileDeposit, dispatchGetBill } = props;
  const tabs = [
    { label: 'Все', value: 'common' },
    { label: 'Списания', value: 'transactions' },
    { label: 'Пополнения', value: 'payments' }
  ];
  //TODO: придумать как оптимизировать
  const paymentMethods = [{ label: 'По счёту', value: 'bankAccount' }, { label: 'Картой', value: 'card' }];
  const [paymentMethod, setPaymentMethod] = useState('card');
  const handlePaymentMethodChange = (event, newValue) => {
    setPaymentMethod(newValue);
  };
  const [tabValue, setTabValue] = useState('common');

  //пока приняли решение отказаться
  // const [visibleOperations, setVisibleOperations] = useState(8);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const monthOptions = [
    { value: '00', label: 'за все время' },
    { value: '01', label: 'за январь' },
    { value: '02', label: 'за февраль' },
    { value: '03', label: 'за март' },
    { value: '04', label: 'за апрель' },
    { value: '05', label: 'за май' },
    { value: '06', label: 'за июнь' },
    { value: '07', label: 'за июль' },
    { value: '08', label: 'за август' },
    { value: '09', label: 'за сентябрь' },
    { value: '10', label: 'за октябрь' },
    { value: '11', label: 'за ноябрь' },
    { value: '12', label: 'за декабрь' }
  ];
  const [selectedMonth, setSelectedMonth] = useState('00');
  // const filteredTransactions = transactions.filter(transaction => {
  //   const transactionMonth = dayjs(transaction.date).format('MM');
  //   return selectedMonth === '00' || transactionMonth === selectedMonth;
  // });

  const dispatch = useDispatch();
  const transactions = useSelector(transactionsSelector);
  const deposits = useSelector(depositsSelector);
  const [showOperationInfo, setShowOperationInfo] = useState(false);
  const [showBalanceDialog, setShowBalanceDialog] = useState(false);
  const [showSecurityPaymentInfo, setShowSecurityPaymentInfo] = useState(false);
  const [operationData, setOperationData] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const OFFICE_NEEDED_SERVICES = new Set([
    SERVICE_TYPE.WORK_PLACE,
    SERVICE_TYPE.MEETING_ROOM,
    SERVICE_TYPE.MULTIMEDIA_MEETING_ROOM,
    SERVICE_TYPE.EVENT_SPACE
  ]);

  const descriptionByType = {
    [SERVICE_TYPE.WORK_PLACE]: 'Рабочее место',
    [SERVICE_TYPE.MEETING_ROOM]: 'Переговорная',
    [SERVICE_TYPE.MULTIMEDIA_MEETING_ROOM]: 'Переговорная'
  };
  const getServiceNameWithDesc = service => {
    const desc = descriptionByType[service.type_id];
    if (!desc || service.is_openspace || service.name.includes('Нефиксированное рабочее место')) {
      return service.name;
    }
    return [desc, service.name].filter(v => v).join(' ');
  };
  const getServiceName = transaction => {
    if (!transaction.service) {
      return '';
    }

    if (!transaction.office || !OFFICE_NEEDED_SERVICES.has(transaction.service.type_id)) {
      return getServiceNameWithDesc(transaction.service);
    }

    return getServiceNameWithDesc(transaction.service);
  };
  const saveFile = (deposit, type) => {
    if (type === 'card') {
      dispatchGetBinaryFileDeposit(deposit.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, 'payment.pdf');
        })
        .catch(() => {});
    } else {
      dispatchGetBill(deposit.id)
        .then(binaryFile => {
          saveAs(binaryFile.data, 'payment.pdf');
        })
        .catch(() => {});
    }
  };
  const renderStatusTitle = loadingStatus => {
    const content = {
      'Новый': () => {
        return (
          <StatusBar type={'pending'}>
            <IconComponent
              type='pending'
              handleAction={() => console.log('pending')}
              defaultColor='#8F9295'
              hoverColor='#c8c9ca'
            />
            <p>Операция в обработке</p>
          </StatusBar>
        );
      },
      'В процессе': () => {
        return (
          <StatusBar type={'pending'}>
            <IconComponent type='pending' handleAction={() => null} defaultColor='#8F9295' hoverColor='#c8c9ca' />
            <p>Операция в обработке</p>
          </StatusBar>
        );
      },
      'Отменен': () => {
        return (
          <StatusBar type={'cancelled'}>
            <IconComponent type='cancel' handleAction={() => null} defaultColor='#BA393A' hoverColor='#c8c9ca' />
            <p>Операция отменена</p>
          </StatusBar>
        );
      },
      'Выполнен': () => {
        return (
          <StatusBar type='accepted'>
            <IconComponent type='accepted' handleAction={() => null} defaultColor='#008d64' hoverColor='#008d64' />
            <p>Операция выполнена</p>
          </StatusBar>
        );
      }
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };

  const loadFirstPage = useCallback(() => {
    dispatch(resetTransactions());
    dispatch(getTransactions(1));
    dispatch(resetDeposits());
    dispatch(getDepositBanks());
    dispatch(getDeposits(1));
  }, [dispatch]);
  useEffect(() => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, [window.innerWidth]);

  useEffect(() => loadFirstPage(), [loadFirstPage]);
  useEffect(() => {
    if (updateCursor && inputRef.current) {
      const formattedValue = formData.amount;
      const position = formattedValue.length - 2;
      inputRef.current.setSelectionRange(position, position);
      setUpdateCursor(false);
    }
  }, [formData, updateCursor]);

  const combinedOperations = useMemo(() => {
    const allOperations = [
      ...transactions.map(transaction => ({ ...transaction, operationType: 'transaction' })),
      ...deposits.map(deposit => ({ ...deposit, operationType: 'deposit' }))
    ];
    return allOperations.sort((a, b) => dayjs(b.created_at) - dayjs(a.created_at));
  }, [transactions, deposits]);
  const handleResize = () => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else setIsTouchDevice(false);
  };
  useResize(handleResize, 0);

  const renderOperations = data => {
    const filteredOperations = data.filter(operation => {
      const operationMonth = dayjs(operation.created_at).format('MM');
      return selectedMonth === '00' ? data : operationMonth === selectedMonth;
    });
    return (
      <OperationsWrapper>
        {filteredOperations.length > 0 ? (
          <OperationContainer>
            {/*{(isTouchDevice ? data : data.slice(0, visibleOperations)).map(item => (*/}
            {filteredOperations.map(item => (
              <Transaction
                key={item.id || item.service_request_id}
                onClick={() => {
                  setOperationData({ ...item });
                  setShowOperationInfo(true);
                }}
              >
                <Details>
                  <Type>
                    {item.operationType === 'transaction' ? 'Списание' : 'Пополнение'}{' '}
                    <LinkArrow type={'small'} defaultColor='#999' width={'12px'} height={'12px'} />
                  </Type>
                  <Description>
                    {item.operationType === 'transaction'
                      ? getServiceName(item)
                      : item.is_security_payment === 1
                      ? 'На обеспечительный баланс'
                      : 'На общий баланс'}
                  </Description>
                  {item.operationType === 'deposit' && item.payment && item.payment.t_name === 'Банковский перевод' && (
                    <Description
                      onClick={event => {
                        event.stopPropagation();
                        saveFile(item, 'bill');
                      }}
                    >
                      <Check>Скачать счёт</Check>
                    </Description>
                  )}
                  <Description>
                    {item.operationType === 'deposit' && item.payment && item.payment_system_id !== 3 && (
                      <Status>{renderStatusTitle(item.status ? item.status : '')}</Status>
                    )}
                  </Description>
                </Details>
                <Details>
                  <Amount positive={item.operationType !== 'transaction'}>
                    {' '}
                    {item.operationType === 'transaction' ? '-' : '+'} {removeZeros(+item.amount)} ₽
                  </Amount>
                  <Date>{dayjs(item.created_at).format('DD.MM.YY HH:MM')}</Date>
                </Details>
              </Transaction>
            ))}
            {/*{data.length > visibleOperations && !isTouchDevice && (*/}
            {/*  <ShowMoreButton*/}
            {/*    onClick={() => {*/}
            {/*      const remainingOperations = data.length - visibleOperations;*/}
            {/*      const operationsToShow = remainingOperations > 8 ? 8 : remainingOperations;*/}
            {/*      setVisibleOperations(visibleOperations + operationsToShow);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Показать ещё*/}
            {/*  </ShowMoreButton>*/}
            {/*)}*/}
          </OperationContainer>
        ) : (
          <OperationContainer>
            <EmptyOperation>Операции за этот месяц отсутствуют</EmptyOperation>
          </OperationContainer>
        )}
      </OperationsWrapper>
    );
  };
  const { handlePayment } = usePaymentController();

  const handleSubmit = useCallback(() => {
    handlePayment({
      type: paymentMethod,
      depositAmount: +formData.amount.replace(' ₽', ''),
      isSecurityPayment: formData.destination === 'securityAccount',
      checkoutDifference: 0
    });
    setShowBalanceDialog(false);
  }, [handlePayment, paymentMethod, formData.amount, formData.destination]);

  return (
    <MainWrapper>
      <PrefillForm>
        <HistoryWrapper>
          <GroupTitle>
            <span>Оплата</span>
            {!isTouchDevice && (
              <StepButton
                handleAction={() => setShowBalanceDialog(true)}
                type={'filled'}
                filledType={'red'}
                title={'Пополнить баланс'}
                spacing={'0'}
              />
            )}
          </GroupTitle>
          <CardsWrapper>
            <BalanceCard title='Общий баланс' balance={account.amount} />
            <BalanceCard
              handleAction={() => setShowSecurityPaymentInfo(true)}
              title='Обеспечительный баланс'
              balance={account.security_payment_amount}
              tooltip={true}
            />
          </CardsWrapper>
          <GroupTitle>
            <p>История операций</p>
            <CustomDropdown
              isFocused={isFocused}
              name={'month'}
              id='custom-select'
              label={''}
              variant='filled'
              value={selectedMonth}
              onChange={handleApplyMonthFilters}
              options={monthOptions}
            />
          </GroupTitle>
          <TabsWrapper>
            <TabsComponent
              tabsContainerStyle={{ width: '100%', height: '48px' }}
              tabStyle={{ height: '40px' }}
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              tabs={tabs}
            />
          </TabsWrapper>
          {tabValue === 'common' && renderOperations(combinedOperations)}
          {tabValue === 'transactions' &&
            renderOperations(transactions.map(transaction => ({ ...transaction, operationType: 'transaction' })))}
          {tabValue === 'payments' &&
            renderOperations(deposits.map(deposit => ({ ...deposit, operationType: 'deposit' })))}
        </HistoryWrapper>
        {showOperationInfo && (
          <OperationInfoDialog
            dialogType='operationInfo'
            saveDocument={(deposit, documentType) => saveFile(deposit, documentType)}
            dialogData={operationData}
            handleClose={() => setShowOperationInfo(false)}
          />
        )}
        {showBalanceDialog && (
          <BalanceDialog
            dialogType={'balance'}
            errors={errors}
            formData={formData}
            handleInputChange={handleInputChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleSelectChange={handleSelectChange}
            handleEraseValue={handleEraseValue}
            destinationOptions={destinationOptions}
            disableButton={disableButton}
            customer={customer}
            handleTabChange={handlePaymentMethodChange}
            tabValue={paymentMethod}
            tabs={paymentMethods}
            handleSubmit={handleSubmit}
            filled={filled}
            isFocused={isFocused}
            handleClose={() => setShowBalanceDialog(false)}
            inputRef={inputRef}
          />
        )}
        {showSecurityPaymentInfo && (
          <GeneralInfoDialog
            dialogType={'generalInfo'}
            dialogData={[
              'Это баланс, из которого будут оплачиваться обеспечительные платежи.\n',
              'Обеспечительный платёж списывается только при аренде кабинетов.\n',
              'Обеспечительный платёж равняется стоимости аренды кабинета на месяц и возвращается в течение 20 дней после завершения аренды, если вы бережно относились к рабочему месту, соблюдали правила рабочего пространства Workki и своевременно совершали оплату аренды.'
            ]}
            title={'Обеспечительный баланс'}
            buttonLabel={'Понятно, спасибо'}
            handleSubmit={() => setShowSecurityPaymentInfo(false)}
          />
        )}
      </PrefillForm>
      {isTouchDevice && (
        <ButtonWrapper>
          <StepButton
            handleAction={() => setShowBalanceDialog(true)}
            type={'filled'}
            filledType={'red'}
            title={'Пополнить баланс'}
            spacing={'0'}
          />
        </ButtonWrapper>
      )}
    </MainWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchGetBinaryFileDeposit: id => dispatch(getBinaryFileDepositReceipt(id)),
  dispatchGetBill: id => dispatch(getBinaryFileDeposit(id))
});

export default connect(
  () => ({}),
  mapDispatchToProps
)(Payments);
