import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryLayout from '../../../../layouts/PrimaryLayout';
import { logoutUser } from '../../../Authentification/api/AuthActions';
import 'svgxuse';
import { styled } from 'styled-components';
import { ProfileCard } from '../../../../shared/ui/components/Profile/ProfileCard';
import { customerAvatarFileSelector, customerSelector } from '../../../../store/selectors/DefaultSelectors';
import Avatar from '@mui/material/Avatar';
import { CustomTextField } from '../../../../shared/ui/components/Field/CustomTextField';
import FormControl from '@mui/material/FormControl';
import { useHistory } from 'react-router-dom';

import { Hints } from '../../../../shared/ui/components/Profile/Hints';
import { useDebounce } from '../../../../shared/utils/hooks/useDebounce';
import { receiveAddressHints, receiveHints } from '../api/ProfileActions';
import { useResize } from '../../../../shared/utils/hooks/useResize';
import { CheckboxComponent } from '../../../../shared/ui/components/Сheckbox';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { StepButton } from '../../../../shared/ui/components/StepButton';
import { PhoneMask } from '../../../../shared/ui/icons/core/PhoneMask';
import { FileUploadPreview } from '../../../../shared/ui/components/Field/FileUploadField';
import { Success } from '../../../../shared/ui/icons/core/Success';
import { formatPhoneNumber } from '../../../../shared/utils/serviceUtils/validationUtils';
import {
  createCustomerCurrentIndividualCompany,
  createCustomerCurrentIndividualInfo,
  getCustomerCompanyData
} from '../../../../services/workki/CustomerActions';
import { InfoAlert } from '../../../../shared/ui/components/Profile/InfoAlert';
import { ArrowBack } from '../../../../shared/ui/icons/core/ArrowBack';
import { TabsComponent } from '../../../../shared/ui/components/Tabs/Tabs';

//TODO - в дальнейшем преобразовать в сущность - разбить на виджеты

export const ProfileFormWrapper = styled.div`
  display: flex;
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  justify-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  @media (min-width: 320px) {
    height: calc(100vh - 100px);
    overflow: auto;
  }
  @media (min-width: 1366px) {
    height: 100vh;
  }
`;
export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100vh;
`;
export const PrefillForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: ${props => props.position};
  overflow: auto;
  @media (min-width: 320px) {
    width: 100%;
    padding: 16px;
    height: calc(100vh - 100px);
    overflow: auto;
  }
  @media (min-width: 768px) {
    min-width: 340px;
    max-width: 650px;
  }
  @media (min-width: 1366px) {
    min-width: 416px;
    max-width: 650px;
  }
  @media (min-width: 1920px) {
    width: 600px;
  }
`;

export const ProfileInfoWidget = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 12px;
`;
export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin: 0;
  }
  :first-child {
    font-weight: 400;
    color: #8f9295;
  }
  :nth-child(2) {
    font-weight: 300;
  }
`;
export const StepLabel = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: ${props => (props.position ? props.position : 'flex-start')};
  align-items: center;
  @media (min-width: 320px) {
    & > p {
      font-weight: 500;
      font-size: ${props => (props.reservation ? '22px' : '18px')};
      margin: 0;
    }
  }
  @media (min-width: 768px) {
    & > p {
      font-weight: 500;
      font-size: 22px;
      margin: 0;
    }
  }
  @media (min-width: 1366px) {
    & > p {
      font-weight: 500;
      font-size: ${props => (props.reservation ? '22px' : '32px')};
      margin: 0;
    }
  }
`;
export const TabsWrapper = styled.div`
  padding: 16px 0;
  width: 100%;
`;
export const AlertWrapper = styled.div`
  margin-top: 8px;
`;
export const FormGroupTitle = styled.div`
  display: flex;
  width: 100%;
  justify-self: flex-start;
  align-items: center;
  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }
  margin: 16px 0;
`;
export const LegalEntityDataBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  justify-self: flex-start;
  width: 100%;
`;
export const LegalEntityTitle = styled.div`
  :first-child {
    font-size: 12px;
    line-height: 16px;
    color: #8f9295;
  }
  & > p {
    margin: 0;
    font-weight: 300;
  }
  padding: 8px 0;
`;
export const LegalEntityInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 22px;
  :first-child {
    color: #8f9295;
    margin-right: 4px;
  }
  :nth-child(2) {
    color: #24272a;
  }
  padding-bottom: 8px;
`;
export const LegalEntityInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;
export const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 18px;
  & > p {
    margin: 0;
    font-size: 14px;
    color: #24272a;
    font-weight: 300;
  }
`;
const SuccessBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 100%;
  align-self: center;
`;
export const SuccessTitle = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
`;
export const SuccessCaption = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;
export const AddressFieldBlock = styled.div`
  margin-bottom: 16px;
`;
const PrefillProfilePage = props => {
  const COUNTRIES_ID = {
    RU: 147
  };
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const makeLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);
  const customer = useSelector(customerSelector);
  const history = useHistory();

  const stringAvatar = name => {
    const initialsArr = name.split(' ');
    return {
      children: initialsArr.length === 1 ? `${initialsArr[0][0]}` : `${initialsArr[0][0]}${initialsArr[1][0]}`
    };
  };
  const cards = [
    {
      title: 'Настройки профиля',
      caption: ''
    }
  ];
  const [step, setStep] = useState('primaryStep');
  const tabs = [
    { label: 'Юрлицо', value: 'legalEntity' },
    { label: 'ИП', value: 'individualEntrepreneur' },
    { label: 'Физлицо', value: 'person' }
  ];
  const [tabValue, setTabValue] = useState('legalEntity');
  const handleTabChange = (event, newValue) => {
    setAnchorEl(null);
    setAddressAnchor(null);
    setTabValue(newValue);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [addressAnchor, setAddressAnchor] = useState(null);

  const handleClose = type => {
    if (type === 'inn') {
      setAnchorEl(null);
    }
    if (type === 'address') {
      setAddressAnchor(null);
    }
  };

  const open = Boolean(anchorEl);
  const addressOpen = Boolean(addressAnchor);
  const [inputWidth, setInputWidth] = useState(50);
  const [innValue, setInnValue] = useState('');
  const [legalEntityData, setLegalEntityData] = useState([]);
  const [fullAddress, setFullAddress] = useState(null);
  const [smallScreen, setSmallScreen] = useState(false);
  const [filled, setFilled] = useState({
    inn: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    address: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    lastName: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    firstName: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    patronymic: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    position: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    email: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    phone: {
      legalEntity: false,
      individualEntrepreneur: false
    }
  });
  const [isFocused, setIsFocused] = useState({
    inn: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    address: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    lastName: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    firstName: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    patronymic: {
      legalEntity: false,
      individualEntrepreneur: false,
      person: false
    },
    position: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    email: {
      legalEntity: false,
      individualEntrepreneur: false
    },
    phone: {
      legalEntity: false,
      individualEntrepreneur: false
    }
  });
  const [errors, setErrors] = useState({
    inn: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    address: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    lastName: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    firstName: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    patronymic: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    position: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    email: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    phone: {
      legalEntity: '',
      individualEntrepreneur: ''
    }
  });
  const [formData, setFormData] = useState({
    inn: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    address: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    lastName: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    firstName: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    patronymic: {
      legalEntity: '',
      individualEntrepreneur: '',
      person: ''
    },
    position: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    email: {
      legalEntity: '',
      individualEntrepreneur: ''
    },
    phone: {
      legalEntity: '',
      individualEntrepreneur: ''
    }
  });
  const [isChecked, setIsChecked] = useState({
    actualAddress: true,
    patronymic: false,
    citizen: false
  });
  const [hintsData, setHintsData] = useState([]);
  const [inn, setInn] = useState('');
  const [address, setAddress] = useState('');
  const [addressHintsData, setAddressHintsData] = useState([]);
  const debouncedInn = useDebounce(inn, 1000);
  const debouncedAddress = useDebounce(address, 1000);
  useEffect(() => {
    if (debouncedInn !== '' && tabValue !== 'person') {
      dispatch(receiveHints(debouncedInn)).then(response => setHintsData(response.data));
    }
  }, [debouncedInn]);
  useEffect(() => {
    if (debouncedAddress !== '' && tabValue !== 'person') {
      dispatch(receiveAddressHints(debouncedAddress)).then(response => setAddressHintsData(response.data));
    }
  }, [debouncedAddress]);
  const isComplexField = fieldName => {
    //TODO переписать логику из-за новых сложных полей
    const flag =
      fieldName === 'inn' ||
      fieldName === 'address' ||
      fieldName === 'lastName' ||
      fieldName === 'firstName' ||
      fieldName === 'patronymic' ||
      fieldName === 'position' ||
      fieldName === 'email' ||
      fieldName === 'phone';
    return flag;
  };

  const handleFocus = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: isComplexField(fieldName) ? { ...prevFocused[fieldName], [type]: true } : true
    }));
  };
  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: isComplexField(fieldName) ? { ...prevFocused[fieldName], [type]: false } : false
    }));
    handleClose(fieldName);
  };
  //Повтор - можно вынести в управление филдами
  const handleInputChange = evt => {
    const { name, value } = evt.target;
    const cleanField = !(name === 'inn' || name === 'address' || name === 'position');
    const inputValue = cleanField ? value.replace(/\s+/g, '') : value;
    if (name === 'inn') {
      if (value !== '') {
        setInn(inputValue);
        setAnchorEl(evt.currentTarget);
      }
    }
    if (name === 'address') {
      if (value !== '') {
        setAddressAnchor(evt.currentTarget);
        setAddress(inputValue);
      }
    }

    updateFilledState(name, inputValue);
    // Добавить валидацию по аналогии с AlternativeLogin - вынести валидационный функционал отдельно
    // handleValidation(name, inputValue);
    updateFormData(name, inputValue);
  };

  const updateFilledState = (name, inputValue) => {
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: isComplexField(name)
        ? {
            ...prevFilled[name],
            [tabValue]: true
          }
        : inputValue !== ''
    }));
  };
  const updateFormData = (name, inputValue) => {
    setFormData(prevState => {
      const type = tabValue;
      if (isComplexField(name)) {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            [type]: name === 'phone' ? formatPhoneNumber(inputValue, setErrors) : inputValue
          }
        };
      } else {
        return {
          ...prevState,
          [name]: inputValue
        };
      }
    });
  };
  const handleEraseValue = (fieldName, type) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: isComplexField(fieldName) ? { ...prevState[fieldName], [type]: '' } : ''
    }));
    if (fieldName === 'inn') {
      setAnchorEl(null);
    } else if (fieldName === 'address') {
      setAddressAnchor(null);
    }
  };
  //Функционал необходимый для ширины поля подсказок
  useEffect(() => {
    if (inputRef.current) {
      const width = inputRef.current.getBoundingClientRect().width;
      setInputWidth(+width + 50);
    }
  }, [formData]);
  const handleResize = () => {
    handleClose();
    if (window.innerWidth < 768) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };
  useResize(handleResize, 0);
  useEffect(() => {
    dispatch(getCustomerCompanyData(innValue)).then(response => setLegalEntityData(response.data));
    const type = tabValue;
    setFormData(prevState => ({
      ...prevState,
      inn: { ...prevState.inn, [type]: innValue }
    }));
  }, [innValue]);
  useEffect(() => {
    handleClose('inn');
  }, [legalEntityData]);
  useEffect(() => {
    setIsChecked(prevState => ({ ...prevState, patronymic: false }));
    //При переключении табов затирать значение на предыдущем шаге
    setInnValue('');
  }, [tabValue]);
  const [files, setFiles] = useState([]);
  const handleConfirmCompany = () => {
    const data = {
      name: legalEntityData.name,
      name_short: legalEntityData.name_short,
      inn: formData.inn[tabValue],
      kpp: legalEntityData.kpp,
      full_address: formData.address[tabValue] ? formData.address[tabValue] : legalEntityData.full_address, //Предусмотреть для ип или введенное из поля
      registered_country_id: COUNTRIES_ID.RU,
      ogrn: legalEntityData.ogrn,
      director_first_name: formData.firstName[tabValue],
      director_middle_name: formData.patronymic[tabValue],
      director_last_name: formData.lastName[tabValue],
      director_post: formData.position[tabValue],
      director_email: formData.email[tabValue],
      director_phone: formData.phone[tabValue],
      city: fullAddress ? fullAddress.city_with_type : '',
      street: fullAddress ? fullAddress.street_with_type : '',
      house: fullAddress ? fullAddress.house : '',
      index: fullAddress ? fullAddress.postal_code : ''
    };
    dispatch(createCustomerCurrentIndividualCompany(data))
      .then(() => setStep('confirmationSuccess'))
      .catch();
  };
  const handleConfirmPerson = () => {
    const data = new FormData();
    data.append('first_name', formData.firstName.person);
    data.append('last_name', formData.lastName.person);
    data.append('middle_name', formData.patronymic.person);
    data.append('passportFile', files[0]);
    dispatch(createCustomerCurrentIndividualInfo(data))
      .then(() => setStep('confirmationSuccess'))
      .catch();
  };
  return (
    <PrimaryLayout>
      <ProfileFormWrapper>
        {step === 'primaryStep' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              {customer && (
                <ProfileInfoWidget>
                  <Avatar
                    sx={{ color: '#8F9295', fontWeight: 500, backgroundColor: '#F4F4F4', marginRight: '12px' }}
                    {...stringAvatar(customer.name)}
                  />
                  <ProfileInfo>
                    <p>{customer.name}</p>
                    <p>{customer.email}</p>
                  </ProfileInfo>
                </ProfileInfoWidget>
              )}
              <InfoAlert
                title={'Подтвердите профиль, чтобы пользоваться услугами коворкинга'}
                link={'Подтвердить профиль'}
                type={'warning'}
                handleAction={() => setStep('profileConfirmation')}
              />
              <ProfileCard
                cards={cards}
                logoutShown={true}
                handleLogout={makeLogout}
                handleFollowLink={() => {
                  setStep('profileConfirmation');
                }}
              />
            </FormContent>
          </PrefillForm>
        )}
        {step === 'profileConfirmation' && tabValue === 'legalEntity' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('primaryStep')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
                <p>Подтверждение профиля</p>
              </StepLabel>
              <TabsWrapper>
                <TabsComponent
                  tabsContainerStyle={{ width: '100%', height: '48px' }}
                  tabStyle={{ height: '40px' }}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  tabs={tabs}
                />
              </TabsWrapper>
              <AlertWrapper>
                <InfoAlert
                  title={'Чтобы пользоваться услугами, пожалуйста, укажите данные о компании.'}
                  type={'info'}
                />
              </AlertWrapper>
              <FormControl fullWidth={true}>
                <CustomTextField
                  error={errors.inn.legalEntity}
                  type='text'
                  name='inn'
                  id='outlined-helperText'
                  label='Название, ИНН или ОГРН юрлица'
                  variant='filled'
                  value={formData.inn.legalEntity}
                  onFocus={() => handleFocus('inn', 'legalEntity')}
                  onBlur={() => handleBlur('inn', 'legalEntity')}
                  onChange={handleInputChange}
                  helperText={errors.inn.legalEntity ? errors.inn.legalEntity : ''}
                  endAdornmentProps={{
                    filled: filled.inn.legalEntity,
                    isFocused: isFocused.inn.legalEntity,
                    handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                  }}
                  autoFocus={true}
                  inputRef={inputRef}
                />
                <Hints
                  open={open}
                  formData={formData}
                  anchorEl={anchorEl}
                  hintsData={hintsData}
                  setInnValue={inn => setInnValue(inn)}
                  inputWidth={inputWidth}
                  type={'inn'}
                />
              </FormControl>
              {legalEntityData && legalEntityData.name && (
                <LegalEntityDataBlock>
                  <LegalEntityTitle>
                    <p>Наименование:</p>
                    <p>{legalEntityData.name.short}</p>
                  </LegalEntityTitle>
                  <LegalEntityInfoWrapper>
                    <LegalEntityInfo>
                      <span>ИНН: </span>
                      <span>{innValue}</span>
                    </LegalEntityInfo>
                    {legalEntityData.requisites.kpp !== '' && (
                      <LegalEntityInfo>
                        <span>КПП: </span>
                        <span>{legalEntityData.requisites.kpp}</span>
                      </LegalEntityInfo>
                    )}
                    {legalEntityData.requisites.ogrn !== '' && (
                      <LegalEntityInfo>
                        <span>ОГРН: </span>
                        <span>{legalEntityData.requisites.ogrn}</span>
                      </LegalEntityInfo>
                    )}
                  </LegalEntityInfoWrapper>
                  <LegalEntityTitle>
                    <p>Юридический адрес: </p>
                    <p>{legalEntityData.address.full_address}</p>
                  </LegalEntityTitle>
                </LegalEntityDataBlock>
              )}
              <CheckboxBlock>
                <CheckboxComponent
                  checked={isChecked.actualAddress}
                  onChange={() =>
                    setIsChecked(prevState => ({ ...prevState, actualAddress: !isChecked.actualAddress }))
                  }
                  name='addressCheckbox'
                  checkedIcon={<CheckBoxRoundedIcon />}
                />
                <p>Совпадает с юридическим</p>
              </CheckboxBlock>
              {!isChecked.actualAddress && (
                <FormControl fullWidth={true}>
                  <AddressFieldBlock>
                    <CustomTextField
                      error={errors.address.legalEntity ? errors.address.legalEntity : ''}
                      type='text'
                      name='address'
                      id='outlined-helperText'
                      label='Фактический адрес'
                      variant='filled'
                      value={formData.address.legalEntity}
                      onFocus={() => handleFocus('address', 'legalEntity')}
                      onBlur={() => handleBlur('address', 'legalEntity')}
                      onChange={handleInputChange}
                      helperText={errors.address.legalEntity ? errors.address.legalEntity : ''}
                      endAdornmentProps={{
                        filled: filled.address.legalEntity,
                        isFocused: isFocused.address.legalEntity,
                        handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                      }}
                      autoFocus={false}
                      inputRef={inputRef}
                    />
                    <Hints
                      open={addressOpen}
                      formData={formData}
                      anchorEl={addressAnchor}
                      inputWidth={inputWidth}
                      addressHintsData={addressHintsData}
                      type={'address'}
                      setFullAddress={value => setFullAddress(value)}
                      errors={errors.address.legalEntity ? errors.address.legalEntity : ''}
                      setErrors={value =>
                        setErrors(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            legalEntity: value
                          }
                        }))
                      }
                      //Исправить название поля
                      setInnValue={address =>
                        setFormData(prevState => ({
                          ...prevState,
                          address: { ...prevState.address, [tabValue]: address }
                        }))
                      }
                    />
                  </AddressFieldBlock>
                </FormControl>
              )}
              {innValue !== '' && (
                <FormControl fullWidth={true}>
                  <StepButton
                    disabled={
                      formData.inn.legalEntity === '' ||
                      errors.address.legalEntity !== '' ||
                      (!fullAddress && !isChecked.actualAddress)
                    }
                    type={'outlined'}
                    title={'Далее'}
                    iconType={'arrow'}
                    handleAction={() => setStep('personalData')}
                  />
                </FormControl>
              )}
            </FormContent>
          </PrefillForm>
        )}
        {step === 'personalData' && tabValue === 'legalEntity' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('profileConfirmation')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
                <p onClick={() => setStep('profileConfirmation')}>Назад</p>
              </StepLabel>
              <AlertWrapper>
                <InfoAlert
                  title={'Данные руководителя или иного уполномоченного лица для заключения договора'}
                  type={'info'}
                />
              </AlertWrapper>
              <FormGroupTitle>
                <p>ФИО и должность руководителя</p>
              </FormGroupTitle>
              <CustomTextField
                error={errors.lastName.legalEntity}
                type='text'
                name='lastName'
                id='outlined-helperText'
                label='Фамилия'
                variant='filled'
                value={formData.lastName.legalEntity}
                onFocus={() => handleFocus('lastName', 'legalEntity')}
                onBlur={() => handleBlur('lastName', 'legalEntity')}
                onChange={handleInputChange}
                helperText={errors.lastName.legalEntity ? errors.lastName.legalEntity : ''}
                endAdornmentProps={{
                  filled: filled.lastName.legalEntity,
                  isFocused: isFocused.lastName.legalEntity,
                  handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                }}
                autoFocus={true}
              />
              <CustomTextField
                error={errors.firstName.legalEntity}
                type='text'
                name='firstName'
                id='outlined-helperText'
                label='Имя'
                variant='filled'
                value={formData.firstName.legalEntity}
                onFocus={() => handleFocus('firstName', 'legalEntity')}
                onBlur={() => handleBlur('firstName', 'legalEntity')}
                onChange={handleInputChange}
                helperText={errors.firstName.legalEntity ? errors.firstName.legalEntity : ''}
                endAdornmentProps={{
                  filled: filled.firstName.legalEntity,
                  isFocused: isFocused.firstName.legalEntity,
                  handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                }}
                autoFocus={false}
              />
              {!isChecked.patronymic && (
                <CustomTextField
                  error={errors.patronymic.legalEntity}
                  type='text'
                  name='patronymic'
                  id='outlined-helperText'
                  label='Отчество'
                  variant='filled'
                  value={formData.patronymic.legalEntity}
                  onFocus={() => handleFocus('patronymic', 'legalEntity')}
                  onBlur={() => handleBlur('patronymic', 'legalEntity')}
                  onChange={handleInputChange}
                  helperText={errors.patronymic.legalEntity ? errors.patronymic.legalEntity : ''}
                  endAdornmentProps={{
                    filled: filled.patronymic.legalEntity,
                    isFocused: isFocused.patronymic.legalEntity,
                    handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                  }}
                  autoFocus={false}
                />
              )}
              <CheckboxBlock>
                <CheckboxComponent
                  checked={isChecked.patronymic}
                  onChange={() => setIsChecked(prevState => ({ ...prevState, patronymic: !isChecked.patronymic }))}
                  name='addressCheckbox'
                  checkedIcon={<CheckBoxRoundedIcon />}
                />
                <p>Нет отчества</p>
              </CheckboxBlock>
              <CustomTextField
                error={errors.position.legalEntity}
                type='text'
                name='position'
                id='outlined-helperText'
                label='Должность'
                variant='filled'
                value={formData.position.legalEntity}
                onFocus={() => handleFocus('position', 'legalEntity')}
                onBlur={() => handleBlur('position', 'legalEntity')}
                onChange={handleInputChange}
                helperText={errors.position.legalEntity ? errors.position.legalEntity : ''}
                endAdornmentProps={{
                  filled: filled.position.legalEntity,
                  isFocused: isFocused.position.legalEntity,
                  handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                }}
                autoFocus={false}
              />
              <FormGroupTitle>
                <p>Контакты руководителя</p>
              </FormGroupTitle>
              <CustomTextField
                error={errors.email.legalEntity}
                type='email'
                name='email'
                id='outlined-helperText'
                label='E-mail'
                variant='filled'
                value={formData.email.legalEntity}
                onFocus={() => handleFocus('email', 'legalEntity')}
                onBlur={() => handleBlur('email', 'legalEntity')}
                onChange={handleInputChange}
                helperText={errors.email.legalEntity ? errors.email.legalEntity : ''}
                endAdornmentProps={{
                  filled: filled.email.legalEntity,
                  isFocused: isFocused.email.legalEntity,
                  handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                }}
                autoFocus={false}
              />
              <CustomTextField
                error={''}
                id='outlined-helperText'
                label='Телефон'
                name='phone'
                helperText={errors.phone.legalEntity ? errors.phone.legalEntity : ''}
                variant='filled'
                type='tel'
                value={formData.phone.legalEntity}
                onChange={handleInputChange}
                placeholder={'999 999-99-99'}
                onFocus={() => handleFocus('phone', 'legalEntity')}
                onBlur={() => handleBlur('phone', 'legalEntity')}
                endAdornmentProps={{
                  filled: filled.phone.legalEntity,
                  isFocused: isFocused.phone.legalEntity,
                  handleEraseValue: name => handleEraseValue(name, 'legalEntity')
                }}
                startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
                autoFocus={false}
              />
              <FormControl fullWidth={true}>
                <StepButton
                  disabled={
                    formData.firstName.legalEntity === '' ||
                    formData.lastName.legalEntity === '' ||
                    formData.email.legalEntity === '' ||
                    (formData.patronymic.legalEntity === '' && !isChecked.patronymic)
                  }
                  type={'filled'}
                  filledType={'red'}
                  title={'Подтвердить'}
                  handleAction={() => handleConfirmCompany()}
                />
              </FormControl>
            </FormContent>
          </PrefillForm>
        )}
        {step === 'confirmationSuccess' && tabValue === 'legalEntity' && (
          <PrefillForm position={'center'}>
            <SuccessBlock>
              <Success defaultColor={'#00A676'} spacing={'0'} />
              <SuccessTitle>Вы подтвердили профиль юридического лица</SuccessTitle>
              <SuccessCaption>Теперь вам доступны все возможности коворкинга.</SuccessCaption>
              <FormControl fullWidth={true}>
                <StepButton
                  handleAction={() => history.push('/workplaces')}
                  disabled={false}
                  type={'filled'}
                  filledType={'black'}
                  title={'Перейти к аренде'}
                />
              </FormControl>
            </SuccessBlock>
          </PrefillForm>
        )}
        {step === 'profileConfirmation' && tabValue === 'individualEntrepreneur' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('primaryStep')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
                <p>Подтверждение профиля</p>
              </StepLabel>
              <TabsWrapper>
                <TabsComponent
                  tabsContainerStyle={{ width: '100%', height: '48px' }}
                  //TODO пока стилизация не работает - починить
                  tabStyle={{ height: '40px' }}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  tabs={tabs}
                />
              </TabsWrapper>
              <AlertWrapper>
                <InfoAlert
                  title={'Чтобы пользоваться услугами, пожалуйста, укажите данные о компании.'}
                  type={'info'}
                />
              </AlertWrapper>
              <FormControl fullWidth={true}>
                <CustomTextField
                  error={errors.inn.individualEntrepreneur}
                  type='text'
                  name='inn'
                  id='outlined-helperText'
                  label='Название, ИНН или ОГРН юрлица'
                  variant='filled'
                  value={formData.inn.individualEntrepreneur}
                  onFocus={() => handleFocus('inn', 'individualEntrepreneur')}
                  onBlur={() => handleBlur('inn', 'individualEntrepreneur')}
                  onChange={handleInputChange}
                  helperText={errors.inn.individualEntrepreneur ? errors.inn.individualEntrepreneur : ''}
                  endAdornmentProps={{
                    filled: filled.inn.individualEntrepreneur,
                    isFocused: isFocused.inn.individualEntrepreneur,
                    handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                  }}
                  autoFocus={true}
                  inputRef={inputRef}
                />
                <Hints
                  open={open}
                  formData={formData}
                  anchorEl={anchorEl}
                  hintsData={hintsData}
                  setInnValue={inn => setInnValue(inn)}
                  inputWidth={inputWidth}
                  type={'inn'}
                />
              </FormControl>
              {legalEntityData && legalEntityData.name && tabValue === 'individualEntrepreneur' && (
                <LegalEntityDataBlock>
                  <LegalEntityTitle>
                    <p>Наименование:</p>
                    <p>{legalEntityData.name.short}</p>
                  </LegalEntityTitle>
                  <LegalEntityInfoWrapper>
                    <LegalEntityInfo>
                      <span>ИНН: </span>
                      <span>{innValue}</span>
                    </LegalEntityInfo>
                    {legalEntityData.requisites.kpp !== '' && (
                      <LegalEntityInfo>
                        <span>КПП: </span>
                        <span>{legalEntityData.requisites.kpp}</span>
                      </LegalEntityInfo>
                    )}
                    {legalEntityData.requisites.ogrn !== '' && (
                      <LegalEntityInfo>
                        <span>ОГРН: </span>
                        <span>{legalEntityData.requisites.ogrn}</span>
                      </LegalEntityInfo>
                    )}
                  </LegalEntityInfoWrapper>
                  <LegalEntityTitle>
                    <p>Адрес регистраци: </p>
                    <p>{legalEntityData.address.full_address}</p>
                  </LegalEntityTitle>
                </LegalEntityDataBlock>
              )}
              {innValue !== '' && (
                <>
                  <FormGroupTitle>
                    <p>Уточните адрес</p>
                  </FormGroupTitle>
                  <FormControl fullWidth={true}>
                    <AddressFieldBlock>
                      <CustomTextField
                        error={errors.address.individualEntrepreneur ? errors.address.individualEntrepreneur : ''}
                        type='text'
                        name='address'
                        id='outlined-helperText'
                        label='Улица и дом'
                        variant='filled'
                        inputRef={inputRef}
                        value={formData.address.individualEntrepreneur}
                        onFocus={() => handleFocus('address', 'individualEntrepreneur')}
                        onBlur={() => handleBlur('address', 'individualEntrepreneur')}
                        onChange={handleInputChange}
                        helperText={errors.address.individualEntrepreneur ? errors.address.individualEntrepreneur : ''}
                        endAdornmentProps={{
                          filled: filled.address.individualEntrepreneur,
                          isFocused: isFocused.address.individualEntrepreneur,
                          handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                        }}
                        autoFocus={false}
                      />
                      <Hints
                        open={addressOpen}
                        formData={formData}
                        anchorEl={addressAnchor}
                        inputWidth={inputWidth}
                        addressHintsData={addressHintsData}
                        errors={errors.address.individualEntrepreneur ? errors.address.individualEntrepreneur : ''}
                        setErrors={value =>
                          setErrors(prevState => ({
                            ...prevState,
                            address: {
                              ...prevState.address,
                              individualEntrepreneur: value
                            }
                          }))
                        }
                        setFullAddress={value => setFullAddress(value)}
                        type={'address'}
                        //Исправить название поля
                        setInnValue={address => {
                          setFormData(prevState => ({
                            ...prevState,
                            address: { ...prevState.address, [tabValue]: address }
                          }));
                        }}
                      />
                    </AddressFieldBlock>
                  </FormControl>
                </>
              )}
              {innValue !== '' && formData.inn.individualEntrepreneur && (
                <FormControl fullWidth={true}>
                  <StepButton
                    disabled={formData.inn.individualEntrepreneur === '' || !fullAddress}
                    type={'outlined'}
                    title={'Далее'}
                    iconType={'arrow'}
                    handleAction={() => setStep('personalData')}
                  />
                </FormControl>
              )}
            </FormContent>
          </PrefillForm>
        )}
        {step === 'personalData' && tabValue === 'individualEntrepreneur' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('profileConfirmation')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
                <p onClick={() => setStep('profileConfirmation')}>Назад</p>
              </StepLabel>
              <AlertWrapper>
                <InfoAlert
                  title={'Данные руководителя или иного уполномоченного лица для заключения договора'}
                  type={'info'}
                />
              </AlertWrapper>
              <FormGroupTitle>
                <p>ФИО и должность руководителя</p>
              </FormGroupTitle>
              <CustomTextField
                error={errors.lastName.individualEntrepreneur}
                type='text'
                name='lastName'
                id='outlined-helperText'
                label='Фамилия'
                variant='filled'
                value={formData.lastName.individualEntrepreneur}
                onFocus={() => handleFocus('lastName', 'individualEntrepreneur')}
                onBlur={() => handleBlur('lastName', 'individualEntrepreneur')}
                onChange={handleInputChange}
                helperText={errors.lastName.individualEntrepreneur ? errors.lastName.individualEntrepreneur : ''}
                endAdornmentProps={{
                  filled: filled.lastName.individualEntrepreneur,
                  isFocused: isFocused.lastName.individualEntrepreneur,
                  handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                }}
                autoFocus={true}
              />
              <CustomTextField
                error={errors.firstName.individualEntrepreneur}
                type='text'
                name='firstName'
                id='outlined-helperText'
                label='Имя'
                variant='filled'
                value={formData.firstName.individualEntrepreneur}
                onFocus={() => handleFocus('firstName', 'individualEntrepreneur')}
                onBlur={() => handleBlur('firstName', 'individualEntrepreneur')}
                onChange={handleInputChange}
                helperText={errors.firstName.individualEntrepreneur ? errors.firstName.individualEntrepreneur : ''}
                endAdornmentProps={{
                  filled: filled.firstName.individualEntrepreneur,
                  isFocused: isFocused.firstName.individualEntrepreneur,
                  handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                }}
                autoFocus={false}
              />
              {!isChecked.patronymic && (
                <CustomTextField
                  error={errors.patronymic.individualEntrepreneur}
                  type='text'
                  name='patronymic'
                  id='outlined-helperText'
                  label='Отчество'
                  variant='filled'
                  value={formData.patronymic.individualEntrepreneur}
                  onFocus={() => handleFocus('patronymic', 'individualEntrepreneur')}
                  onBlur={() => handleBlur('patronymic', 'individualEntrepreneur')}
                  onChange={handleInputChange}
                  helperText={errors.patronymic.individualEntrepreneur ? errors.patronymic.individualEntrepreneur : ''}
                  endAdornmentProps={{
                    filled: filled.patronymic.individualEntrepreneur,
                    isFocused: isFocused.patronymic.individualEntrepreneur,
                    handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                  }}
                  autoFocus={false}
                />
              )}
              <CheckboxBlock>
                <CheckboxComponent
                  checked={isChecked.patronymic}
                  onChange={() => setIsChecked(prevState => ({ ...prevState, patronymic: !isChecked.patronymic }))}
                  name='addressCheckbox'
                  checkedIcon={<CheckBoxRoundedIcon />}
                />
                <p>Нет отчества</p>
              </CheckboxBlock>
              <CustomTextField
                error={errors.position.individualEntrepreneur}
                type='text'
                name='position'
                id='outlined-helperText'
                label='Должность'
                variant='filled'
                value={formData.position.individualEntrepreneur}
                onFocus={() => handleFocus('position', 'individualEntrepreneur')}
                onBlur={() => handleBlur('position', 'individualEntrepreneur')}
                onChange={handleInputChange}
                helperText={errors.position.individualEntrepreneur ? errors.position.individualEntrepreneur : ''}
                endAdornmentProps={{
                  filled: filled.position.individualEntrepreneur,
                  isFocused: isFocused.position.individualEntrepreneur,
                  handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                }}
                autoFocus={false}
              />
              <FormGroupTitle>
                <p>Контакты руководителя</p>
              </FormGroupTitle>
              <CustomTextField
                error={errors.email.individualEntrepreneur}
                type='email'
                name='email'
                id='outlined-helperText'
                label='E-mail'
                variant='filled'
                value={formData.email.individualEntrepreneur}
                onFocus={() => handleFocus('email', 'individualEntrepreneur')}
                onBlur={() => handleBlur('email', 'individualEntrepreneur')}
                onChange={handleInputChange}
                helperText={errors.email.individualEntrepreneur ? errors.email.individualEntrepreneur : ''}
                endAdornmentProps={{
                  filled: filled.email.individualEntrepreneur,
                  isFocused: isFocused.email.individualEntrepreneur,
                  handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                }}
                autoFocus={false}
              />
              <CustomTextField
                error={''}
                id='outlined-helperText'
                label='Телефон'
                name='phone'
                helperText={errors.phone.individualEntrepreneur ? errors.phone.individualEntrepreneur : ''}
                variant='filled'
                type='tel'
                value={formData.phone.individualEntrepreneur}
                onChange={handleInputChange}
                placeholder={'999 999-99-99'}
                onFocus={() => handleFocus('phone', 'individualEntrepreneur')}
                onBlur={() => handleBlur('phone', 'individualEntrepreneur')}
                endAdornmentProps={{
                  filled: filled.phone.individualEntrepreneur,
                  isFocused: isFocused.phone.individualEntrepreneur,
                  handleEraseValue: name => handleEraseValue(name, 'individualEntrepreneur')
                }}
                startAdornment={<PhoneMask spacing='0' defaultColor='#24272A' />}
                autoFocus={false}
              />
              <FormControl fullWidth={true}>
                <StepButton
                  disabled={
                    formData.firstName.individualEntrepreneur === '' ||
                    formData.lastName.individualEntrepreneur === '' ||
                    formData.email.individualEntrepreneur === '' ||
                    (formData.patronymic.individualEntrepreneur === '' && !isChecked.patronymic)
                  }
                  filledType={'red'}
                  type={'filled'}
                  title={'Подтвердить'}
                  handleAction={() => handleConfirmCompany()}
                />
              </FormControl>
            </FormContent>
          </PrefillForm>
        )}
        {step === 'confirmationSuccess' && tabValue === 'individualEntrepreneur' && (
          <PrefillForm position={'center'}>
            <SuccessBlock>
              <Success defaultColor={'#00A676'} spacing={'0'} />
              <SuccessTitle>Вы подтвердили профиль ИП</SuccessTitle>
              <SuccessCaption>Теперь вам доступны все возможности коворкинга.</SuccessCaption>
              <FormControl fullWidth={true}>
                <StepButton
                  handleAction={() => history.push('/workplaces')}
                  disabled={false}
                  type={'filled'}
                  filledType={'black'}
                  title={'Перейти к аренде'}
                />
              </FormControl>
            </SuccessBlock>
          </PrefillForm>
        )}
        {step === 'profileConfirmation' && tabValue === 'person' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('primaryStep')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
                <p>Подтверждение профиля</p>
              </StepLabel>
              <TabsWrapper>
                <TabsComponent
                  tabsContainerStyle={{ width: '100%', height: '48px' }}
                  tabStyle={{ width: '104px', height: '40px' }}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  tabs={tabs}
                />
              </TabsWrapper>
              <AlertWrapper>
                <InfoAlert title={'Чтобы пользоваться услугами, пожалуйста, укажите свои данные.'} type={'info'} />
              </AlertWrapper>
              <CustomTextField
                error={errors.lastName.person}
                type='text'
                name='lastName'
                id='outlined-helperText'
                label='Фамилия'
                variant='filled'
                value={formData.lastName.person}
                onFocus={() => handleFocus('lastName', 'person')}
                onBlur={() => handleBlur('lastName', 'person')}
                onChange={handleInputChange}
                helperText={errors.lastName.person ? errors.lastName.person : ''}
                endAdornmentProps={{
                  filled: filled.lastName.person,
                  isFocused: isFocused.lastName.person,
                  handleEraseValue: name => handleEraseValue(name, 'person')
                }}
                autoFocus={true}
              />
              <CustomTextField
                error={errors.firstName.person}
                type='text'
                name='firstName'
                id='outlined-helperText'
                label='Имя'
                variant='filled'
                value={formData.firstName.person}
                onFocus={() => handleFocus('firstName', 'person')}
                onBlur={() => handleBlur('firstName', 'person')}
                onChange={handleInputChange}
                helperText={errors.firstName.person ? errors.firstName.person : ''}
                endAdornmentProps={{
                  filled: filled.firstName.person,
                  isFocused: isFocused.firstName.person,
                  handleEraseValue: name => handleEraseValue(name, 'person')
                }}
                autoFocus={false}
              />
              {!isChecked.patronymic && (
                <CustomTextField
                  error={errors.patronymic.person}
                  type='text'
                  name='patronymic'
                  id='outlined-helperText'
                  label='Отчество'
                  variant='filled'
                  value={formData.patronymic.person}
                  onFocus={() => handleFocus('patronymic', 'person')}
                  onBlur={() => handleBlur('patronymic', 'person')}
                  onChange={handleInputChange}
                  helperText={errors.patronymic.person ? errors.patronymic.person : ''}
                  endAdornmentProps={{
                    filled: filled.patronymic.person,
                    isFocused: isFocused.patronymic.person,
                    handleEraseValue: name => handleEraseValue(name, 'person')
                  }}
                  autoFocus={false}
                />
              )}
              <CheckboxBlock>
                <CheckboxComponent
                  checked={isChecked.patronymic}
                  onChange={() => setIsChecked(prevState => ({ ...prevState, patronymic: !isChecked.patronymic }))}
                  name='addressCheckbox'
                  checkedIcon={<CheckBoxRoundedIcon />}
                />
                <p>Нет отчества</p>
              </CheckboxBlock>
              <FormControl fullWidth={true}>
                <StepButton
                  disabled={
                    formData.firstName.person === '' ||
                    formData.lastName.person === '' ||
                    (formData.patronymic.person === '' && !isChecked.patronymic)
                  }
                  type={'outlined'}
                  title={'Далее'}
                  iconType={'arrow'}
                  handleAction={() => setStep('personalData')}
                />
              </FormControl>
            </FormContent>
          </PrefillForm>
        )}
        {step === 'personalData' && tabValue === 'person' && (
          <PrefillForm position={'flex-start'}>
            <FormContent>
              <StepLabel onClick={() => setStep('profileConfirmation')}>
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} />
                <p onClick={() => setStep('profileConfirmation')}>Назад</p>
              </StepLabel>
              <AlertWrapper>
                <InfoAlert title={'Пожалуйста, подтвердите свою личность.'} type={'info'} />
              </AlertWrapper>
              <CheckboxBlock>
                <CheckboxComponent
                  checked={isChecked.citizen}
                  onChange={() => setIsChecked(prevState => ({ ...prevState, citizen: !isChecked.citizen }))}
                  name='addressCheckbox'
                  checkedIcon={<CheckBoxRoundedIcon />}
                />
                <p>Я не гражданин Российской Федерации</p>
              </CheckboxBlock>
              <FormGroupTitle>
                <p>Паспорт гражданина РФ</p>
              </FormGroupTitle>
              <FileUploadPreview files={files} setFiles={value => setFiles(value)} />
              <StepButton
                disabled={files.length === 0}
                type={'filled'}
                filledType={'red'}
                title={'Подтвердить'}
                handleAction={() => handleConfirmPerson()}
              />
            </FormContent>
          </PrefillForm>
        )}
        {step === 'confirmationSuccess' && tabValue === 'person' && (
          <PrefillForm position={'center'}>
            <SuccessBlock>
              <Success defaultColor={'#00A676'} spacing={'0'} />
              <SuccessTitle>Вы подтвердили профиль физического лица</SuccessTitle>
              <SuccessCaption>Теперь вам доступны все возможности коворкинга.</SuccessCaption>
              <FormControl fullWidth={true}>
                <StepButton
                  handleAction={() => history.push('/workplaces')}
                  disabled={false}
                  type={'filled'}
                  filledType={'black'}
                  title={'Перейти к аренде'}
                />
              </FormControl>
            </SuccessBlock>
          </PrefillForm>
        )}
      </ProfileFormWrapper>

      {/*{props.isLoading ? <PageLoader /> : <PrefillProfile location={props.location} />}*/}
    </PrimaryLayout>
  );
};

export default PrefillProfilePage;
